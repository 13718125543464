import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import PersonvernFil from "../container/personvern/PersonvernFil";

const Personvern = () => {
  return (
    <React.Fragment>
      <SEO title="Cannibal | Personvern" />
      <Header />
      <PersonvernFil />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Personvern;