import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import FormPassword from "../container/Form/FormPassword";

const Password = () => {
  return (
    <React.Fragment>
      <SEO title="Cannibal | Referanser" />
      <Header />
      <FormPassword />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Password;
