import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import firebase from "./utils/firebase";

// CSS File Here
import "swiper/css";
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/scss/style.scss";


const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
