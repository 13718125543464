import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { useState, useEffect, useContext, createContext } from "react";
import { getFirestore } from "firebase/firestore";

export const firebaseApp = initializeApp({
  // apiKey: "AIzaSyALZBbsosmGTbRRBPEN9Gj1UTUEU70mR_Y",
  // authDomain: "cannibal-casting-as.firebaseapp.com",
  // projectId: "cannibal-casting-as",
  // storageBucket: "cannibal-casting-as.appspot.com",
  // messagingSenderId: "88228605819",
  // appId: "1:88228605819:web:dee444ebfad72fc95a17de",
  // measurementId: "G-VX11N0B8HB",

  apiKey: "AIzaSyAwiq-G1zBtkdOa5Xm1jvD3HupTzH94Ysg",
  authDomain: "casting-f2a0e.firebaseapp.com",
  projectId: "casting-f2a0e",
  storageBucket: "casting-f2a0e.appspot.com",
  messagingSenderId: "1066375035008",
  appId: "1:1066375035008:web:c03894cad1750574ef4e65",
  measurementId: "G-ZP0X0CNGJM",
});

export const AuthContext = createContext(null);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError);
    return () => unsubscribe();
  }, []);
  return <AuthContext.Provider value={{ user, error, setUser }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth?.user != null };
};
