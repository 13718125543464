import React from "react";
import { NavLink } from "react-router-dom";
// import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";

const MobileNavMenu = () => {
  // const onClickHandler = (e) => {
  //   const target = e.currentTarget;
  //   const parentEl = target.parentElement;
  //   if (
  //     parentEl?.classList.contains("menu-toggle") ||
  //     target.classList.contains("menu-toggle")
  //   ) {
  //     const element = target.classList.contains("icon") ? parentEl : target;
  //     const parent = getClosest(element, "li");
  //     const childNodes = parent.childNodes;
  //     const parentSiblings = getSiblings(parent);
  //     parentSiblings.forEach((sibling) => {
  //       const sibChildNodes = sibling.childNodes;
  //       sibChildNodes.forEach((child) => {
  //         if (child.nodeName === "UL") {
  //           slideUp(child, 1000);
  //         }
  //       });
  //     });
  //     childNodes.forEach((child) => {
  //       if (child.nodeName === "UL") {
  //         slideToggle(child, 1000);
  //       }
  //     });
  //   }
  // };
  return (
    <nav className="site-mobile-menu">
      <ul>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/"}>
            <span className="menu-text">hjem</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/om"}>
            <span className="menu-text">om oss</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/referanser"}>
            <span className="menu-text">referanser</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/logginn"}>
            <span className="menu-text">logg inn</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/registrer"}>
            <span className="menu-text">registrer deg</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
