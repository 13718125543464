import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Contact = lazy(() => import("./pages/Contact"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile"));
import { AuthContextProvider } from "./utils/firebase/firebase";
import Vilkar from "./pages/Vilkar";
import Personvern from "./pages/Personvern";
import Disclaimer from "./pages/Disclaimer";
import CookiesPage from "./pages/Cookies";
import Password from "./pages/Password";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <AuthContextProvider>
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
            <Routes>
              <Route
                path={`${process.env.PUBLIC_URL + "/"}`}
                element={<HomeThree />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/hjem"}`}
                element={<HomeThree />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/om"}`}
                element={<About />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/referanser"}`}
                element={<Service />}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/kontakt"}`}
                element={<Contact />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/personvern"}`}
                element={<Personvern />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/vilkar"}`}
                element={<Vilkar />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/cookies"}`}
                element={<CookiesPage />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/disclaimer"}`}
                element={<Disclaimer />}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/spillefilm/:id"}`}
                element={<WorkDetails />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/registrer"}`}
                element={<Register />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/logginn"}`}
                element={<Login />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/password"}`}
                element={<Password />}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/profile"}`}
                element={<Profile />}
              />
            </Routes>
          </Suspense>
        </NavScrollTop>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
