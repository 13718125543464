import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import Disclaimer from "../container/personvern/Disclaimer";

const DisclaimerPage = () => {
  return (
    <React.Fragment>
      <SEO title="Cannibal | Disclaimer" />
      <Header />
      <Disclaimer />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default DisclaimerPage;