import { Fragment, useState } from "react";
import Logo from "../../components/logo/Logo";
import NavBar from "../../components/NavBar/NavBar";
import Lgn from "../../components/Btn/Lgn";
import MobileMenu from "../../components/NavBar/MobileMenu";
import PropTypes from "prop-types";
import { useAuthState } from "../../utils/firebase/firebase";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import BtnWhite from "../../components/Btn/BtnWhite";

const Header = ({ bg = "" }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthState();
  const [ofcanvasShow, setOffcanvasShow] = useState(false);

  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const handleLogoutClick = () => {
    signOut(getAuth()).then(() => {
      navigate("/");
    });
  };

  // const [scroll, setScroll] = useState(0);
  // const [headerTop, setHeaderTop] = useState(0);

  // useEffect(() => {
  //   const header = document.querySelector(".header-section");
  //   setHeaderTop(header.offsetTop);
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   setScroll(window.scrollY);
  // };
  return (
    <Fragment>
      <div
        className={`header-section header-transparent sticky-header section ${bg} border-bottom-0`}
      >
        <div className="header-inner">
          <div className="container position-relative">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-2 col-auto order-0">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/cannibal/cannibal-logo-halv.png`}
                />
              </div>
              <div className="col-auto col-xl d-flex align-items-center justify-content-xl-right justify-content-end order-2 order-xl-1">
                <div className="menu-column-area d-none d-xl-block position-static">
                  <NavBar />
                </div>

                <div className="header-mobile-menu-toggle d-xl-none ms-sm-2">
                  <button
                    type="button"
                    className="toggle"
                    onClick={onCanvasHandler}
                  >
                    <i className="icon-top"></i>
                    <i className="icon-middle"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </div>

              <div className="col-xl-3 col d-none d-sm-flex justify-content-end order-1 order-xl-2">
                {isAuthenticated ? (
                  <>
                    <div
                      className="btn btn-loggin btn-light btn-hover-primary"
                      onClick={handleLogoutClick}
                    >
                      logg ut
                    </div>
                    <BtnWhite name="min side" url="/profile" />
                  </>
                ) : (
                  <>
                    <Lgn name="logg Inn" url="/logginn" />
                    <BtnWhite name="Registrer deg" url="/registrer" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </Fragment>
  );
};

Header.propTypes = {
  bg: PropTypes.string,
};

export default Header;
