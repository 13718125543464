import React from "react";
import { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { firebaseApp } from "../../utils/firebase/firebase";

function App() {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const auth = getAuth(firebaseApp);
  const [email, setEmail] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    setAlertMessage(null);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmail("");
        setAlertType("success");
        setAlertMessage(
          "An email has been sent to you providing instructions traduction"
        );
        // Sign in successful, redirect to home page or display success message
      })
      .catch((error) => {
        setAlertType("danger");
        let message = "Server Error";

        switch (error.code) {
          case "auth/missing-email": {
            message = "Email address missing";
            break;
          }
          case "auth/invalid-email": {
            message = "Email address invalid";
            break;
          }
          case "auth/user-not-found": {
            setEmail("");
            setAlertType("success");
            message =
              "An email has been sent to you providing instructions traduction";
            break;
          }
        }
        setAlertMessage(message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  }

  return (
    <MDBRow className="section section-padding-top background-registrer">
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center">
          <MDBCol lg="9" className="my-5 pt-10">
            <form onSubmit={handleSubmit}>
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="12" className="ps-5">
                      <h1 className="mb-4">glemt passord</h1>
                      {alertMessage && (
                        <div className={`alert alert-${alertType}`}>
                          {alertMessage}
                        </div>
                      )}
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />

                  <MDBRow className="align-items-center pt-4 pb-3">
                    <MDBCol md="3" className="ps-5">
                      <h6 className="mb-0">Epost</h6>
                    </MDBCol>

                    <MDBCol md="9" className="pe-5">
                      <MDBInput
                        size="lg"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <hr className="mx-n3" />

                  <MDBBtn
                    noRipple
                    className="my-4"
                    size="lg"
                    style={{ marginRight: "50px" }}
                  >
                    Tilbakestille passord
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBRow>
  );
}

export default App;
